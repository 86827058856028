import getCpf from './getCpf'
import getPropostasConsignado from './getPropostasConsignado'
import getPropostasConsignadoVisitante from './getPropostasConsignadoVisitante'
import getContratosConsignado from './getContratosConsignado'
import getContratosConsignadoVisitante from './getContratosConsignadoVisitante'
import postGerarGuid from './postGerarGuid'
import postEfetuarLogin from './postEfetuarLogin'
import getSolicitaToken from './getSolicitaToken'
import getAntecipacaoFgts from './getAntecipacaoFgts'
import postTaxas from './postTaxas'
import getContratosGarantia from './getContratosGarantia'
import getContratos from './getContratos'
import getCliente from './getCliente'
import postTrackingNewRelic from './postTrackingNewRelic'
import getPropostaFGTS from './getPropostaFGTS'
import getContratosFGTS from './getContratosFGTS'
import getContratosImobiliario from './getContratosImobiliario'
import getElegivel from './getElegivel'
import postLivenessBiometria from './postLivenessBiometria'
import getUsuarioLogadoInfo from './getUsuarioLogadoInfo'

export default {
  getCpf,
  getPropostasConsignado,
  getContratosConsignado,
  postGerarGuid,
  postEfetuarLogin,
  getSolicitaToken,
  getAntecipacaoFgts,
  postTaxas,
  getContratosGarantia,
  getContratosConsignadoVisitante,
  getContratos,
  getPropostasConsignadoVisitante,
  getCliente,
  postTrackingNewRelic,
  getPropostaFGTS,
  getContratosFGTS,
  getContratosImobiliario,
  getElegivel,
  postLivenessBiometria,
  getUsuarioLogadoInfo,
}
