/**
 * Actions Types
 */

export enum UsuarioTypes {
  SET_NAME = '@usuario/SET_NOME',
  SET_EMAIL = '@usuario/SET_EMAIL',
  SET_PHONE = '@usuario/SET_PHONE',
  SET_PROPOSAL = '@usuario/SET_PROPOSAL',
  SET_LIVENESS_DONE = '@usuario/SET_LIVENESS_DONE',
  SET_DOCUMENT = '@usuario/SET_DOCUMENT',
  SET_AUCTION_ID = '@usuario/SET_AUCTION_ID',
  SET_MULTIPLE_TRANSACTIONS = '@usuario/SET_MULTIPLE_TRANSACTIONS',
  SET_ACCOUNT_HOLDER = '@usuario/SET_ACCOUNT_HOLDER',
  SET_AUTHENTICATION_PROCESS = '@usuario/SET_AUTHENTICATION_PROCESS',
  SET_COVENANT = '@usuario/SET_COVENANT',

  SET_USER_INFO = '@usuario/SET_USER_INFO',
  SET_LOADING_INFO = '@usuario/SET_LOADING_INFO',

  GET_USER_INFO = '@usuario/GET_USER_INFO',
}
export interface UsuarioState {
  info: ResponseUsuario
  loadingInfo: boolean
}

export interface ResponseUsuario {
  readonly covenant?: number
  readonly accountHolder: boolean
  readonly authenticationProcess: string
  readonly document: string
  readonly name: string
  readonly email: string
  readonly phone: string
  readonly proposal: string
  // Só exige a Biometria caso esse boleano seja falso E o auctionId seja true.
  readonly livenessDone: boolean
  // ID de Transação do Leilão
  readonly auctionId: string
  // Se for false, formaliza a proposta. Se for True, leva para listagem de proposta.
  readonly hasMultipleAuctionOpenedTransactions: boolean

  // Caso seja login antigo o response retorna document, name e somente esse campo abaixo a mais
  readonly unroll?: boolean
  readonly authenticationFlow: string
}

export interface UserInfoPayload {
  callbackAuctionSingleProposal: (document: string, proposal: string) => void
  callbackLiveness: () => void
  callbackDefault?: () => void
  callbackAuthenticationFlow: (authenticationFlow: string) => void
  type: string
}
