import { BaseServices, HttpMethod } from '@services/base'

export interface UserGet {
  accessToken: string
  origem: string
}
export default (data: UserGet) => {
  const pathname = `${process.env.REACT_APP_DADOS_USUARIO}` // portal/bff/v1/authentication
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST_CONSIGNADO // https://cc-api.uatbi.com.br
  const headers =
    data.origem === 'site' ? { Authorization: `Bearer ${data.accessToken}` } : { Authorization: '' }

  const response = BaseServices.request<UserInfoType>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface UserInfoType {
  covenant?: number
  accountHolder: boolean
  authenticationProcess: string
  document: string
  name: string
  email: string
  phone: string
  proposal: string
  livenessDone: boolean
  auctionId: string
  hasMultipleAuctionOpenedTransactions: boolean
  unroll?: boolean
  authenticationFlow: string
}
