import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'

import { setLoadingLiveness, setRetryLiveness, setUnicoId } from '../actions'

interface Props {
  payload: {
    base64: string
    encrypted: string
    callback: () => void
  }
  type: string
}

export type ErroType = {
  response: { status: HttpStatus; data: { message: string } }
  message: string
  status: HttpStatus
  data: {
    message: string
  }
}

export default function* postFotoBiometriaLivenessSaga({ payload }: Props) {
  const { cpf } = yield* select((s: ApplicationState) => s.cpf)
  const { name, email, phone, proposal } = yield* select((s: ApplicationState) => s.usuario.info)
  const { origem } = yield select((state: ApplicationState) => state.ui.navigation)
  const { accessToken } = yield select((state: ApplicationState) => state.consignado.login)
  try {
    const response = yield* call(services.postLivenessBiometria, {
      document: cpf,
      name,
      email,
      celular: phone,
      proposalCode: proposal ? String(proposal) : '',
      onlySelfie: true,
      photo: payload.base64,
      encryptedPhoto: payload.encrypted,
      origem,
      accessToken,
      finalizeProposalPendency: false,
      isFullCaptureFlow: false,
    })

    yield put(setUnicoId(response.data.unicoId))
    yield put(setLoadingLiveness(false))
    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(setLoadingLiveness(false))
    if (erroTyped?.status === HttpStatus.PRECONDITION_FAILED) {
      yield put(
        showError({
          title:
            erroTyped?.message ||
            erroTyped?.data?.message ||
            erroTyped?.response.data.message ||
            'Ocorreu um erro',
          message: 'Erro ao validar a biometria, por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => setRetryLiveness(true),
          onClose: () => setRetryLiveness(true),
          historyBack: true,
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Erro ao validar a biometria, por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => setRetryLiveness(true),
          onClose: () => setRetryLiveness(true),
          historyBack: true,
        }),
      )
    }
  }
}
