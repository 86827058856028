import { action, Action } from 'typesafe-actions'

import { ResponseUsuario, UserInfoPayload, UsuarioTypes } from './types'

export const setUsuarioName = (payload: string): Action => action(UsuarioTypes.SET_NAME, payload)

export const setUsuarioEmail = (payload: string): Action => action(UsuarioTypes.SET_EMAIL, payload)

export const setUsuarioPhone = (payload: string): Action => action(UsuarioTypes.SET_PHONE, payload)

export const setUsuarioProposal = (payload: string): Action =>
  action(UsuarioTypes.SET_PROPOSAL, payload)

export const setUsuarioHasLiveness = (payload: string): Action =>
  action(UsuarioTypes.SET_LIVENESS_DONE, payload)

export const setDocument = (payload: string): Action => action(UsuarioTypes.SET_DOCUMENT, payload)

export const setAuctionId = (payload: string): Action =>
  action(UsuarioTypes.SET_AUCTION_ID, payload)

export const setHasMultipleTransactions = (payload: boolean): Action =>
  action(UsuarioTypes.SET_MULTIPLE_TRANSACTIONS, payload)

export const setAccountHolder = (payload: boolean): Action =>
  action(UsuarioTypes.SET_ACCOUNT_HOLDER, payload)

export const setAuthenticationProcess = (payload: string): Action =>
  action(UsuarioTypes.SET_AUTHENTICATION_PROCESS, payload)

export const setCovenant = (payload: number): Action => action(UsuarioTypes.SET_COVENANT, payload)

export const setUserInfo = (payload: ResponseUsuario): Action =>
  action(UsuarioTypes.SET_USER_INFO, payload)

export const setLoadingInfo = (payload: boolean): Action =>
  action(UsuarioTypes.SET_LOADING_INFO, payload)

export const getUserInfo = (payload: UserInfoPayload): Action =>
  action(UsuarioTypes.GET_USER_INFO, payload)
