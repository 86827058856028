import { Reducer } from 'redux'

import { UsuarioTypes, UsuarioState } from './types'

const INITIAL_STATE: UsuarioState = {
  info: {
    name: '',
    email: '',
    phone: '',
    proposal: '',
    livenessDone: false,
    covenant: undefined,
    accountHolder: false,
    authenticationProcess: '',
    document: '',
    hasMultipleAuctionOpenedTransactions: false,
    auctionId: '',
    authenticationFlow: '',
  },
  loadingInfo: false,
}

const reducer: Reducer<UsuarioState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UsuarioTypes.SET_NAME:
      return { ...state, name: action.payload }
    case UsuarioTypes.SET_EMAIL:
      return { ...state, email: action.payload }
    case UsuarioTypes.SET_PHONE:
      return { ...state, phone: action.payload }
    case UsuarioTypes.SET_PROPOSAL:
      return { ...state, proposal: action.payload }
    case UsuarioTypes.SET_LIVENESS_DONE:
      return { ...state, livenessDone: action.payload }
    case UsuarioTypes.SET_DOCUMENT:
      return { ...state, document: action.payload }
    case UsuarioTypes.SET_AUCTION_ID:
      return { ...state, auctionId: action.payload }
    case UsuarioTypes.SET_MULTIPLE_TRANSACTIONS:
      return { ...state, hasMultipleAuctionOpenedTransactions: action.payload }
    case UsuarioTypes.SET_ACCOUNT_HOLDER:
      return { ...state, accountHolder: action.payload }
    case UsuarioTypes.SET_AUTHENTICATION_PROCESS:
      return { ...state, authenticationProcess: action.payload }
    case UsuarioTypes.SET_COVENANT:
      return { ...state, covenant: action.payload }
    case UsuarioTypes.SET_USER_INFO:
      return { ...state, info: action.payload }
    case UsuarioTypes.SET_LOADING_INFO:
      return { ...state, loadingInfo: action.payload }

    default:
      return state
  }
}

export default reducer
