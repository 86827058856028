import OAuthAssistant from '@curity/oauth-assistant'
import { UNROLL_BRAZIL_PARAM } from '@utils/constants'

export const isBrowser = typeof window !== 'undefined'

const isUnrollBrazil = isBrowser ? window.location.search.includes('desenrola-brasil') : false

// const isNewOauth = isBrowser ? localStorage.getItem('isNewOauth') : false

const oAuthScopesNewLogin = [
  'cpc-portal-bff-service:write',
  'cpc-portal-bff-service:read',
  'cp:consignado-visitante:read',
  'cp:consignado-visitante:write',
  'cp:consignado-visitante:restrito',
  'ci-visitante-api',
]

const oAuthScopesOldLogin = [
  'cpc-portal-bff-service:write',
  'cpc-portal-bff-service:read',
  'cp:consignado-visitante:read',
  'cp:consignado-visitante:restrito',
  'ci-visitante-api',
]

export const oAuthScopes = isUnrollBrazil ? oAuthScopesOldLogin : oAuthScopesNewLogin

const debug = process.env.REACT_APP_ENVIROMENT === 'development'
export const siteUrl =
  process.env.REACT_APP_ENVIROMENT === 'development'
    ? 'http://localhost:3000'
    : process.env.REACT_APP_API_HOST_EMPRESTIMO_NOVO
const baseCurityUrl = process.env.REACT_APP_HOST_CURITY as string

const issuer = `${baseCurityUrl}/generic/oauth`
const clientIdNewLogin = 'credit-authenticator'
const clientIdOldLogin = 'credito-consignado-area-visitante-auth'
const clientId = isUnrollBrazil ? clientIdOldLogin : clientIdNewLogin
const openidConfigurationUrl = `${issuer}/.well-known/openid-configuration`
const baseAppUrl = siteUrl as string
const redirectUri = `${siteUrl}/emprestimo${isUnrollBrazil ? UNROLL_BRAZIL_PARAM : ''}` as string

const settings: OAuthAssistant.Settings = {
  flow_type: 'code',
  base_url: baseCurityUrl,
  client_id: clientId,
  issuer,
  redirect_uri: redirectUri,
  for_origin: baseAppUrl,
  allowed_origins: [isBrowser ? window.origin : ''],
  jwt_sig_public_key: {
    format: 'issuer',
    value: null,
  },
  ignore_not_before: false,
  ignore_expiration: false,
  clock_tolerance: 0,
  debug,
  openid_configuration_url: openidConfigurationUrl,
}

const oAuthAssistant = isBrowser ? new OAuthAssistant(settings) : null

export function sso() {
  return oAuthAssistant?.authorizeHiddenFrame({
    scope: oAuthScopes,
  })
}

export default oAuthAssistant || null
