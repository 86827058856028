import { applyMiddleware, combineReducers, createStore, Store, compose, AnyAction } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import rootSaga from './rootSaga'
import ui from './ui'
import { ApplicationState, RootTypes } from './types'
import consignado from './consignado'
import cpf from './cpf'
import pessoal from './pessoal'
import antecipacaoFgts from './antecipacaoFgts'
import antecipacaoSalario from './antecipacaoSalario'
import imobiliario from './imobiliario'
import antecipacaoVeicular from './antecipacaoVeicular'
import biometria from './biometria'
import usuario from './usuario'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware))

export const reducers = combineReducers({
  ui: persistReducer({ key: 'ui', storage }, ui),
  consignado: persistReducer({ key: 'consignado', storage }, consignado),
  cpf: persistReducer({ key: 'cpf', storage }, cpf),
  pessoal: persistReducer({ key: 'pessoal', storage }, pessoal),
  antecipacaoFgts: persistReducer({ key: 'antecipacaoFgts', storage }, antecipacaoFgts),
  antecipacaoSalario: persistReducer({ key: 'antecipacaoSalario', storage }, antecipacaoSalario),
  imobiliario: persistReducer({ key: 'imobiliario', storage }, imobiliario),
  antecipacaoVeicular: persistReducer({ key: 'antecipacaoVeicular', storage }, antecipacaoVeicular),
  biometria: persistReducer({ key: 'biometria', storage }, biometria),
  usuario: persistReducer({ key: 'usuario', storage }, usuario),
})

const rootReducer = (state: ReturnType<typeof reducers> | undefined, action: AnyAction) => {
  if (action.type === RootTypes.CLEAR) {
    storage.removeItem('persist:ui')
    storage.removeItem('persist:consignado')
    storage.removeItem('persist:cpf')
    storage.removeItem('persist:pessoal')
    storage.removeItem('persist:antecipacaoFgts')
    storage.removeItem('persist:antecipacaoSalario')
    storage.removeItem('persist:imobiliario')
    storage.removeItem('persist:antecipacaoVeicular')
    storage.removeItem('persist:biometria')
    storage.removeItem('persist:usuario')

    state = undefined
  }

  return reducers(state, action)
}

const store: Store<ApplicationState> = createStore(rootReducer, enhancer)

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }
