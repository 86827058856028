import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'
import { ServiceResponseError } from '@exceptions'
import { clearStore } from '@store/actions'
import { setCpf, setRedirectToUnroll } from '@store/cpf/actions'

import { getUserInfo, setLoadingInfo, setUserInfo } from '../actions'

interface Props {
  payload: {
    callbackAuctionSingleProposal: (document: string, proposal: string) => void
    callbackLiveness: () => void
    callbackDefault: () => void
    callbackAuthenticationFlow: (authenticationFlow: string) => void
  }
  type: string
}

export default function* getDadosUsuarioLogadoSaga({ payload }: Props) {
  const { origem, isLogged } = yield select((state: ApplicationState) => state.ui.navigation)
  const { accessToken } = yield select((state: ApplicationState) => state.consignado.login)

  try {
    const { data } = yield* call(services.getUsuarioLogadoInfo, {
      accessToken,
      origem,
    })
    yield put(setUserInfo(data))
    yield put(setCpf(data?.document))
    // Confere se é desenrola-brasil
    yield put(setRedirectToUnroll(Boolean(data?.unroll)))
    yield put(setLoadingInfo(false))

    if (data?.auctionId) {
      // Se for Leilão
      if (!data.livenessDone && !data.hasMultipleAuctionOpenedTransactions) {
        // Se for Leilão e Não tiver biometria e só tiver uma proposta
        payload.callbackLiveness()
        return
      }
      if (data.livenessDone && !data.hasMultipleAuctionOpenedTransactions) {
        // Se for Leilão, já tem biometria e só tiver uma proposta
        setTimeout(() => payload.callbackAuctionSingleProposal(data.document, data.proposal), 5000)
        return
      }
    }

    if (data.authenticationFlow && data.authenticationFlow !== 'ALL') {
      payload.callbackAuthenticationFlow(data.authenticationFlow)
    }
    // Não sendo Leilão segue para o fluxo atual
    // Sendo Leilão porém com mais de uma proposta segue para fluxo atual
  } catch (error) {
    yield put(setLoadingInfo(false))
    const ErroTyped = error as ServiceResponseError

    if (origem === 'site' && ErroTyped?.status === 401 && isLogged) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          onClose: () => clearStore(),
          actionCallback: () => clearStore(),
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Erro ao buscar informações, por favor tente novamente.',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => getUserInfo({ ...payload, type: '' }),
          onClose: () => getUserInfo({ ...payload, type: '' }),
        }),
      )
    }
  }
}
