import React, { Suspense, lazy } from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import qs from 'qs'
import { useDispatch, useSelector } from 'react-redux'

import HeaderAndFooter from '@templates/HeaderAndFooter'
import HeaderLogoffWeb from '@molecules/HeaderLogoffWeb'
import { GoBackConfig } from '@utils/goBackConfig'
import FallbackPage from '@organisms/FallbackPage'
import { ApplicationState } from '@store/types'
import ContratoModule from '@interco/cp-contratos-ui-lib'
import PortalBoletoModule from '@interco/cr-portal-boletos-lib-ui'
import PropostaModule from '@interco/cp-propostas-ui-lib'
import { clearStore } from '@store/actions'

import routes from './routes'
import PrivateRoute from './PrivateRoute'

const RootPage = lazy(() => import('@pages/RootPage'))
const Login = lazy(() => import('@pages/Login'))
const HomeEmprestimo = lazy(() => import('@pages/HomeEmprestimo'))
const Consignado = lazy(() => import('@pages/Consignado'))
const Imobiliario = lazy(() => import('@pages/Imobiliario'))
const Pessoal = lazy(() => import('@pages/Pessoal'))
const SaqueAniversario = lazy(() => import('@pages/SaqueAniversario'))
const InstrucoesBiometria = lazy(() => import('@pages/Biometria/Instrucoes'))
const Biometria = lazy(() => import('@pages/Biometria/Camera'))

const configOutros = {
  projectId: 'InterPF',
  projectNumber: '10262246805931025',
  mobileSdkAppId: '3:658014:js',
  hostname: 'https://emprestimo.inter.co',
  hostInfo: 'L/v3RuUAh32izPP8kktuSa3PHrnugdrglAsfgxpJ5Vw=',
  hostKey: 'N658Subu0EKlFnEN6gkq4UyThc26tWtPmfv5w79EkPxClLB28HxTNoe4HO72IlrB',
}
const configInss = {
  projectId: 'InterPF',
  projectNumber: '600498724895161461',
  mobileSdkAppId: '3:658014:js',
  hostname: 'https://emprestimo.inter.co',
  hostInfo: 'L/v3RuUAh32izPP8kktuSa3PHrnugdrglAsfgxpJ5Vw=',
  hostKey: '6/WvMOzaktZokECw/TQ4vtLpyhTB8beZpZH4gPwSFKFDtjSHPab6XDn8wNGAOPgL',
}

const modelsPath = `${process.env.PUBLIC_URL}/models`
const resourcePath = `${process.env.PUBLIC_URL}/resources`
const Routes = () => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { cpf } = useSelector((state: ApplicationState) => state.cpf)
  const { name } = useSelector((state: ApplicationState) => state.usuario.info)
  const { location } = window
  const params = qs.parse(location?.search, { ignoreQueryPrefix: true })
  const { accessToken } = useSelector((state: ApplicationState) => state.consignado.login)
  const { contratoSelecionado } = useSelector(
    (state: ApplicationState) => state.consignado.contratos,
  )
  const { propostaSelecionada } = useSelector(
    (state: ApplicationState) => state.consignado.propostas,
  )
  const dispatch = useDispatch()

  return (
    <>
      {params?.app !== 'true' && !isApp ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          useEnterprise
        >
          <BrowserRouter>
            <GoBackConfig />
            <HeaderAndFooter>
              <HeaderLogoffWeb />
              <Switch>
                <Suspense fallback={<FallbackPage />}>
                  <PrivateRoute path={routes.ROOT} exact>
                    <RootPage />
                  </PrivateRoute>
                  <Route path={routes.LOGIN} exact>
                    <Login />
                  </Route>
                  <Route path={routes.HOME} exact>
                    <HomeEmprestimo />
                  </Route>
                  <PrivateRoute path={routes.EMPRESTIMO_CONSIGNADO} exact>
                    <Consignado />
                  </PrivateRoute>
                  <PrivateRoute path={routes.EMPRESTIMO_IMOBILIARIO} exact>
                    <Imobiliario />
                  </PrivateRoute>
                  <PrivateRoute path={routes.EMPRESTIMO_PESSOAL} exact>
                    <Pessoal />
                  </PrivateRoute>
                  <PrivateRoute path={routes.SAQUE_ANIVERSARIO} exact>
                    <SaqueAniversario />
                  </PrivateRoute>
                  <PrivateRoute path={routes.BIOMETRIA_INSTRUCOES} exact>
                    <InstrucoesBiometria />
                  </PrivateRoute>
                  <PrivateRoute path={routes.BIOMETRIA_VIDEO} exact>
                    <Biometria />
                  </PrivateRoute>
                  <PrivateRoute path="/contratos">
                    <ContratoModule
                      isApp={isApp}
                      accessToken={accessToken}
                      apiHost={process.env.REACT_APP_HOST_CONSIGNADO}
                      apiHostRefin={process.env.REACT_APP_REFINANCIAMENTO_CONSIGNADO}
                      userName={name}
                      cpf={cpf}
                      environment={process.env.REACT_APP_ENVIROMENT}
                      numeroContrato={contratoSelecionado}
                      onLoginExpirado={() => dispatch(clearStore())}
                    />
                  </PrivateRoute>
                  <PrivateRoute path={routes.DESENROLA_BRASIL}>
                    <PortalBoletoModule
                      isApp={isApp}
                      accessToken={accessToken}
                      apiHost={process.env.REACT_APP_HOST_MEIOS_PAGAMENTOS}
                      environment={process.env.REACT_APP_ENVIROMENT}
                      onLoginExpirado={() => dispatch(clearStore())}
                    />
                  </PrivateRoute>
                  <PrivateRoute path="/propostas">
                    <PropostaModule
                      isApp={isApp}
                      accessToken={accessToken}
                      apiHost={process.env.REACT_APP_HOST_CONSIGNADO}
                      urlBack={process.env.REACT_APP_API_HOST_EMPRESTIMO_NOVO}
                      environment={process.env.REACT_APP_ENVIROMENT}
                      numeroProposta={propostaSelecionada?.codigoProposta}
                      tipo={propostaSelecionada?.tipo}
                      isDigital={propostaSelecionada?.digital}
                      cpf={propostaSelecionada?.cpf}
                      valorProposta={propostaSelecionada?.valorProposta}
                      modelsPath={modelsPath}
                      resourcePath={resourcePath}
                      configInss={configInss}
                      configOutros={configOutros}
                      onLoginExpirado={() => dispatch(clearStore())}
                      urlConsignadoProposta={process.env.REACT_APP_FORMALIZACAO_LEILAO}
                    />
                  </PrivateRoute>
                </Suspense>
              </Switch>
            </HeaderAndFooter>
          </BrowserRouter>
        </GoogleReCaptchaProvider>
      ) : (
        <BrowserRouter>
          <GoBackConfig />
          <Switch>
            <Suspense fallback={<FallbackPage />}>
              <Route path={routes.ROOT} exact component={RootPage} />
              <Route path={routes.HOME} exact component={HomeEmprestimo} />
              <Route path={routes.EMPRESTIMO_CONSIGNADO} exact component={Consignado} />
              <Route path={routes.EMPRESTIMO_PESSOAL} exact component={Pessoal} />
              <Route path={routes.SAQUE_ANIVERSARIO} exact component={SaqueAniversario} />
              <ContratoModule
                isApp={isApp}
                accessToken={accessToken}
                apiHost={process.env.REACT_APP_HOST_CONSIGNADO}
                apiHostRefin={process.env.REACT_APP_REFINANCIAMENTO_CONSIGNADO}
                userName={name}
                cpf={cpf}
                environment={process.env.REACT_APP_ENVIROMENT}
                numeroContrato={contratoSelecionado}
              />
              <PortalBoletoModule
                isApp={isApp}
                accessToken={accessToken}
                apiHost={process.env.REACT_APP_HOST_MEIOS_PAGAMENTOS}
                environment={process.env.REACT_APP_ENVIROMENT}
              />
              <PropostaModule
                isApp
                apiHost={process.env.REACT_APP_HOST_CONSIGNADO}
                environment={process.env.REACT_APP_ENVIROMENT}
                numeroProposta={params?.numeroProposta}
                tipo={params?.tipo}
                isDigital={params?.digital}
                cpf={params?.cpf}
                valorProposta={params?.valorProposta}
                modelsPath={modelsPath}
                resourcePath={resourcePath}
                configInss={configInss}
                configOutros={configOutros}
              />
            </Suspense>
          </Switch>
        </BrowserRouter>
      )}
    </>
  )
}
export default Routes
