import styled from 'styled-components'

import { InterUIButton } from '@interco/inter-ui-react-lib'
import { Colors } from '@interco/cp-react-ui-lib'
import { BreadCrumbs } from '@interco/inter-ui/components/BreadCrumbs'

export const Button = styled(InterUIButton)`
  & > button {
    min-width: 70px;
    min-height: 10px;
    padding: 8px;
    font-size: 10px;
    line-height: 12px;
  }
`

export const GridHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 24px;
`

export const HeaderLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  > div:last-of-type {
    margin-left: 0;
  }
`

export const HeaderNameLogout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > .name {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`

export const BreadCrumbsCustom = styled(BreadCrumbs)`
  margin: 0;
`

export const GridCpf = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  align-items: center;
`

export const SpanCpf = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${Colors.GRAY500};
`
