import { BaseServices, HttpMethod } from './base'

export interface IBiometria {
  document: string
  name: string
  email: string
  celular: string
  proposalCode: string
  onlySelfie: boolean
  photo: string
  encryptedPhoto: string
  origem: string
  accessToken: string
  finalizeProposalPendency: boolean
  isFullCaptureFlow: boolean
}

export interface IResponseBiometria {
  unicoId: string
}

export default async (data: IBiometria) => {
  const pathname = process.env.REACT_APP_BIOMETRIA_LIVENESS
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers =
    data.origem === 'site' ? { Authorization: `Bearer ${data.accessToken}` } : { Authorization: '' }

  const response = await BaseServices.request<IResponseBiometria>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
