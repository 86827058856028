import React from 'react'

import { useSelector } from 'react-redux'

import { InterUISeparator } from '@interco/inter-ui-react-lib'
import { Text } from '@interco/inter-ui/components/Text'
import SignOut from '@interco/icons/orangeds/MD/sign-out'
import Arrow from '@interco/icons/bidis/layout/arrow'
import { P } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'
import { useAuth } from '@hooks'
import useMediaQuery from '@hooks/useMediaQuery'

import {
  BreadCrumbsCustom,
  Button,
  GridCpf,
  GridHeader,
  HeaderLayout,
  HeaderNameLogout,
  SpanCpf,
} from './styles'

const HeaderLogoffWeb = () => {
  const { loading } = useSelector((state: ApplicationState) => state.cpf)
  const { isApp, isLogged } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { cpf } = useSelector((state: ApplicationState) => state.cpf)
  const { name } = useSelector((state: ApplicationState) => state.usuario.info)
  const { logout } = useAuth()
  const smallScreen = useMediaQuery('(max-width: 768px)')

  return !isApp && isLogged && !loading ? (
    <>
      {smallScreen ? (
        <>
          <GridHeader>
            <HeaderLayout>
              <HeaderNameLogout>
                <div className="name">
                  <Text variant="caption-1">Olá!</Text>
                  <Text variant="body-3" bold colorWeight={500}>
                    {name}
                  </Text>
                </div>
                <SignOut
                  height={24}
                  width={24}
                  color="#FF7A00"
                  onClick={() => {
                    logout()
                  }}
                />
              </HeaderNameLogout>
              <BreadCrumbsCustom
                configs={{
                  separator: <Arrow height={8} width={8} color="#6A6C72" />,
                  textProps: { variant: 'caption-1', colorWeight: 400 },
                }}
                params={[{ text: 'Área do cliente' }, { text: 'Produtos' }]}
              />
            </HeaderLayout>
          </GridHeader>
        </>
      ) : (
        <>
          <GridHeader>
            <GridCpf>
              <P margin="0" fontSize="12px" lineHeight="15px">
                {'CPF '}
                <SpanCpf>{cpf}</SpanCpf>
              </P>
            </GridCpf>
            <Button
              variant="secondary"
              width="70px"
              onClick={() => {
                logout()
              }}
            >
              Sair
            </Button>
          </GridHeader>
          <InterUISeparator height="2px" />
        </>
      )}
    </>
  ) : (
    <></>
  )
}

export default HeaderLogoffWeb
