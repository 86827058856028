import { action, Action } from 'typesafe-actions'

import { CallbackBiometria, CallbackLastLiveness, ILastLiveness, BiometriaTypes } from './types'

export const setBase64 = (payload: string): Action => action(BiometriaTypes.SET_BASE64, payload)

export const setEncrypted = (payload: string): Action =>
  action(BiometriaTypes.SET_ENCRYPTED, payload)

export const resetLiveness = (): Action => action(BiometriaTypes.RESET_LIVENESS)

export const setUnicoId = (payload: string): Action => action(BiometriaTypes.SET_UNICO_ID, payload)

export const setLoadingLiveness = (payload: boolean): Action =>
  action(BiometriaTypes.SET_LOADING_LIVENESS, payload)

export const setError = (payload: boolean): Action => action(BiometriaTypes.SET_ERROR, payload)

export const getLastLivenessAction = (payload: CallbackLastLiveness): Action =>
  action(BiometriaTypes.GET_LAST_LIVENESS, payload)

export const postFotoBiometriaLiveness = (payload: CallbackBiometria): Action =>
  action(BiometriaTypes.POST_FOTO_BIOMETRIA_LIVENESS, payload)

export const setLastLiveness = (payload: ILastLiveness): Action =>
  action(BiometriaTypes.SET_LAST_LIVENESS, payload)

export const setRetryLiveness = (payload: boolean): Action =>
  action(BiometriaTypes.SET_RETRY_LIVENESS, payload)
