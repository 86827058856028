import { all } from 'typed-redux-saga'

import watchCpf from './cpf/saga'
import watchPropostas from './consignado/propostas/saga'
import watchContratos from './consignado/contratos/saga'
import watchLogin from './login/saga'
import watchAntecipacaoFgts from './antecipacaoFgts/saga'
import watchAntecipacaoSalario from './antecipacaoSalario/saga'
import watchPessoalContratos from './pessoal/contratos/saga'
import watchTracking from './tracking/saga'
import watchContratoImobiliario from './imobiliario/contratoImobiliario/saga'
import watchAntecipacaoVeicular from './antecipacaoVeicular/saga'
import watchBiometria from './biometria/saga'
import watchUsuario from './usuario/saga'

export default function* rootSaga() {
  yield* all([
    watchCpf(),
    watchPropostas(),
    watchContratos(),
    watchLogin(),
    watchAntecipacaoFgts(),
    watchAntecipacaoSalario(),
    watchPessoalContratos(),
    watchTracking(),
    watchContratoImobiliario(),
    watchAntecipacaoVeicular(),
    watchBiometria(),
    watchUsuario(),
  ])
}
